.editor_wrapper {
  border-radius: 4px;
}

.editor_for {
  margin-left: 10px;
}

.ql-toolbar {
  border-top: unset !important;
  border-right: unset !important;
  border-left: unset !important;
}

.ql-container {
  border: unset !important;
}

.ql-picker-options,
.ql-tooltip {
  z-index: 2 !important;
}
