/* h3 {
  margin: 30px 0px 20px 0px !important;
} */
.tableTitle {
  font-weight: 600;
  padding: 5px;
  width: 30%;
  margin: 20px 0px;
}
.partnerTitle {
  margin: 30px 0px 10px 0px;
}
.partnerTable {
  margin: 10px 0px;
}
.partnerTable td {
  padding: 5px;
}
